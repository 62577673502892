import { createContext, useContext, useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import axios from "axios";

const DemoContext = createContext({demoName:{},setDemoName: ()=>{},demo:{}})

const DemoContextProvider = ({children}) => {
    const cookies = new Cookies();
    const [demoName, setDemoName] = useState(cookies.get('demoName')||new URLSearchParams(document.location.search).get('demoName'))
    const [app] = useState(new URLSearchParams(document.location.search).get('appId')|| cookies.get('appId')  || "xw3gc8ubrq6avpxmb8xaax4x6r")
    const [demo, setDemo] = useState()


    useEffect(() => {
        if(app && demoName){
            axios.get(`${process.env.REACT_APP_DEMO_API_ENDPOINT}/bootstrap/${app}/${demoName}`,)
            .then(response => {setDemo(response.data)})
        }
    },[demoName,app])

    return (
        <DemoContext.Provider value={{demoName,setDemoName,demo}}>
            {children}
        </DemoContext.Provider>
    )
}
export const useDemoContext = ()=> useContext(DemoContext)
export default DemoContextProvider