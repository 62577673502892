import React from 'react'
import './DemoPlatformFooter.css'
import { Image } from 'semantic-ui-react';

const DemoPlatformFooter = ({userpicture,logoutAction}) => {
    return (
        <footer className="footer">
            <img src="./logo.png" alt="mobile.demo.okta.com logo" className="brandLogo"/>
            <span className="userControls" onClick={logoutAction}>Logout <Image src={userpicture} avatar size='mini'/></span>
        </footer>
    );
};
export default DemoPlatformFooter;
