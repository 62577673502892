import './App.css';
import { useAuth0 } from "@auth0/auth0-react";
import DemoPlatformFooter from './components/DemoPlatformFooter';
import Appetize from './components/appetize';
import { Container } from 'semantic-ui-react';
import { Dimmer, Loader } from 'semantic-ui-react';
import Cookies from 'universal-cookie';
import DemoContextProvider from './DemoContext';

function App() {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const cookies = new Cookies();

  const triggerLogout= () => {
    logout({ returnTo: window.location.origin })
  }

  if (isLoading) {
    return (
      <Dimmer blurring inverted active id="innerDimmer">
        <Loader inverted>Loading</Loader>
      </Dimmer>
    )
  }
  if (error) {
    window.location.replace(`${process.env.REACT_APP_ERROR_URL}?client_id=${process.env.REACT_APP_CLIENTID}&error=${error.name}&error_description=${error.message}`);
    return null
  }

  if (isAuthenticated) {
    return (
          <DemoContextProvider>
            <Container fluid className="App">
                <Container className="AppContent">
                    <Appetize/>
                </Container>
            </Container>
            <DemoPlatformFooter userpicture={user.picture} logoutAction={triggerLogout}></DemoPlatformFooter>
          </DemoContextProvider>
    );
  } else {
    var searchParams = new URLSearchParams(document.location.search)
    if(searchParams.get('appId')){
      cookies.set('appId', searchParams.get('appId'), { path: '/' });
    }
    if(searchParams.get('demoName')){
      cookies.set('demoName', searchParams.get('demoName'), { path: '/' });
    }
    return loginWithRedirect();
  }
}

export default App;
